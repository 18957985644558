.rotuine-detail-view {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .rotuine-detail-view .div {
    background-color: var(--white);
    height: 2174px;
    overflow: hidden;
    position: relative;
    width: 393px;
  }
  
  .rotuine-detail-view .overlap {
    height: 594px;
    left: 0;
    position: absolute;
    top: -6px;
    width: 394px;
  }
  
  .rotuine-detail-view .routine {
    height: 535px;
    left: 0;
    position: absolute;
    top: 59px;
    width: 394px;
  }
  
  .rotuine-detail-view .overlap-group {
    height: 535px;
    position: relative;
  }
  
  .rotuine-detail-view .img {
    height: 300px;
    left: 0;
    position: absolute;
    top: 0;
    width: 393px;
  }
  
  .rotuine-detail-view .routine-description {
    height: 289px;
    left: 1px;
    position: absolute;
    top: 246px;
    width: 393px;
  }
  
  .rotuine-detail-view .frame-wrapper {
    background-image: url(https://c.animaapp.com/Ko3Ssjzi/img/rectangle-116.svg);
    background-size: 100% 100%;
    height: 299px;
    left: -1px;
    position: relative;
    top: -7px;
  }
  
  .rotuine-detail-view .frame {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 12px;
    left: 24px;
    position: relative;
    top: 32px;
    width: 350px;
  }
  
  .rotuine-detail-view .text-wrapper {
    color: #ff2d55;
    font-family: var(--callout-semibold-16-font-family);
    font-size: var(--callout-semibold-16-font-size);
    font-style: var(--callout-semibold-16-font-style);
    font-weight: var(--callout-semibold-16-font-weight);
    letter-spacing: var(--callout-semibold-16-letter-spacing);
    line-height: var(--callout-semibold-16-line-height);
    margin-top: -1px;
    position: relative;
    width: 321px;
  }
  
  .rotuine-detail-view .p {
    color: #000000;
    font-family: var(--title-1-bold-28-font-family);
    font-size: var(--title-1-bold-28-font-size);
    font-style: var(--title-1-bold-28-font-style);
    font-weight: var(--title-1-bold-28-font-weight);
    letter-spacing: var(--title-1-bold-28-letter-spacing);
    line-height: var(--title-1-bold-28-line-height);
    position: relative;
    width: 321px;
  }
  
  .rotuine-detail-view .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
  }
  
  .rotuine-detail-view .ellipse {
    height: 30px;
    object-fit: cover;
    position: relative;
    width: 30px;
  }
  
  .rotuine-detail-view .text-wrapper-2 {
    color: var(--system-gray-06-dark);
    font-family: var(--caption-1-semibold-12-font-family);
    font-size: var(--caption-1-semibold-12-font-size);
    font-style: var(--caption-1-semibold-12-font-style);
    font-weight: var(--caption-1-semibold-12-font-weight);
    letter-spacing: var(--caption-1-semibold-12-letter-spacing);
    line-height: var(--caption-1-semibold-12-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .rotuine-detail-view .text-wrapper-3 {
    color: var(--system-gray-02-light);
    font-family: var(--caption-1-regular-12-font-family);
    font-size: var(--caption-1-regular-12-font-size);
    font-style: var(--caption-1-regular-12-font-style);
    font-weight: var(--caption-1-regular-12-font-weight);
    letter-spacing: var(--caption-1-regular-12-letter-spacing);
    line-height: var(--caption-1-regular-12-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .rotuine-detail-view .text-wrapper-4 {
    color: #000000;
    font-family: var(--footnote-regular-13-font-family);
    font-size: var(--footnote-regular-13-font-size);
    font-style: var(--footnote-regular-13-font-style);
    font-weight: var(--footnote-regular-13-font-weight);
    letter-spacing: var(--footnote-regular-13-letter-spacing);
    line-height: var(--footnote-regular-13-line-height);
    position: relative;
    width: 338px;
  }
  
  .rotuine-detail-view .rectangle {
    background-color: var(--white);
    height: 60px;
    left: 0;
    position: absolute;
    top: 6px;
    width: 393px;
  }
  
  .rotuine-detail-view .text-wrapper-5 {
    color: var(--system-pink-light);
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 400;
    height: 58px;
    left: 129px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    width: 155px;
  }
  
  .rotuine-detail-view .line {
    height: 1px;
    left: 24px;
    object-fit: cover;
    position: absolute;
    top: 630px;
    width: 344px;
  }
  
  .rotuine-detail-view .text-wrapper-6 {
    color: var(--system-gray-06-dark);
    font-family: var(--title-2-bold-22-font-family);
    font-size: var(--title-2-bold-22-font-size);
    font-style: var(--title-2-bold-22-font-style);
    font-weight: var(--title-2-bold-22-font-weight);
    left: 24px;
    letter-spacing: var(--title-2-bold-22-letter-spacing);
    line-height: var(--title-2-bold-22-line-height);
    position: absolute;
    top: 654px;
    width: 321px;
  }
  
  .rotuine-detail-view .group-wrapper {
    align-items: center;
    background-color: #ffffff;
    border-color: var(--system-gray-06-light);
    border-top-style: solid;
    border-top-width: 1px;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-end;
    left: 0;
    padding: 0px 0px 18px;
    position: absolute;
    top: 2115px;
  }
  
  .rotuine-detail-view .group {
    height: 41px;
    position: relative;
    width: 393px;
  }
  
  .rotuine-detail-view .group-2 {
    height: 24px;
    left: 19px;
    position: absolute;
    top: 17px;
    width: 24px;
  }
  
  .rotuine-detail-view .div-wrapper {
    height: 21px;
    left: 242px;
    position: absolute;
    top: 18px;
    width: 128px;
  }
  
  .rotuine-detail-view .frame-3 {
    align-items: center;
    display: inline-flex;
    gap: 24px;
    position: relative;
  }
  
  .rotuine-detail-view .group-3 {
    height: 18.53px;
    position: relative;
    width: 48px;
  }
  
  .rotuine-detail-view .text-wrapper-7 {
    color: var(--system-gray-06-dark);
    font-family: var(--caption-1-semibold-12-font-family);
    font-size: var(--caption-1-semibold-12-font-size);
    font-style: var(--caption-1-semibold-12-font-style);
    font-weight: var(--caption-1-semibold-12-font-weight);
    height: 14px;
    left: 24px;
    letter-spacing: var(--caption-1-semibold-12-letter-spacing);
    line-height: var(--caption-1-semibold-12-line-height);
    position: absolute;
    top: 2px;
    white-space: nowrap;
  }
  
  .rotuine-detail-view .heart {
    height: 19px;
    left: 0;
    position: absolute;
    top: 0;
    width: 19px;
  }
  
  .rotuine-detail-view .group-4 {
    height: 21.46px;
    margin-right: -2px;
    position: relative;
    width: 60px;
  }
  
  .rotuine-detail-view .text-wrapper-8 {
    color: var(--system-gray-06-dark);
    font-family: var(--caption-1-semibold-12-font-family);
    font-size: var(--caption-1-semibold-12-font-size);
    font-style: var(--caption-1-semibold-12-font-style);
    font-weight: var(--caption-1-semibold-12-font-weight);
    height: 14px;
    left: 26px;
    letter-spacing: var(--caption-1-semibold-12-letter-spacing);
    line-height: var(--caption-1-semibold-12-line-height);
    position: absolute;
    top: 4px;
    white-space: nowrap;
  }
  
  .rotuine-detail-view .bubble-left {
    height: 21px;
    left: 0;
    position: absolute;
    top: 0;
    width: 22px;
  }
  
  .rotuine-detail-view .line-2 {
    height: 1px;
    left: -15977px;
    object-fit: cover;
    position: absolute;
    top: -3200px;
    width: 393px;
  }
  
  .rotuine-detail-view .overlap-2 {
    height: 1327px;
    left: 25px;
    position: absolute;
    top: 709px;
    width: 345px;
  }
  
  .rotuine-detail-view .line-3 {
    height: 849px;
    left: 5px;
    position: absolute;
    top: 17px;
    width: 2px;
  }
  
  .rotuine-detail-view .group-5 {
    height: 630px;
    left: 0;
    position: absolute;
    top: 0;
    width: 345px;
  }
  
  .rotuine-detail-view .frame-4 {
    align-items: center;
    display: inline-flex;
    gap: 12px;
    left: 0;
    position: absolute;
    top: 0;
  }
  
  .rotuine-detail-view .ellipse-2 {
    background-color: var(--system-pink-light);
    border-radius: 6px;
    height: 12px;
    position: relative;
    width: 12px;
  }
  
  .rotuine-detail-view .text-wrapper-9 {
    color: var(--system-pink-light);
    font-family: var(--title-3-semibold-20-font-family);
    font-size: var(--title-3-semibold-20-font-size);
    font-style: var(--title-3-semibold-20-font-style);
    font-weight: var(--title-3-semibold-20-font-weight);
    letter-spacing: var(--title-3-semibold-20-letter-spacing);
    line-height: var(--title-3-semibold-20-line-height);
    margin-top: -1px;
    position: relative;
    width: 321px;
  }
  
  .rotuine-detail-view .frame-5 {
    align-items: center;
    background-color: var(--system-gray-06-light);
    border-radius: 0px 12px 12px 12px;
    display: inline-flex;
    gap: 12px;
    justify-content: center;
    left: 23px;
    overflow: hidden;
    padding: 24px;
    position: absolute;
    top: 37px;
  }
  
  .rotuine-detail-view .text-wrapper-10 {
    color: var(--system-gray-06-dark);
    font-family: var(--footnote-regular-13-font-family);
    font-size: var(--footnote-regular-13-font-size);
    font-style: var(--footnote-regular-13-font-style);
    font-weight: var(--footnote-regular-13-font-weight);
    letter-spacing: var(--footnote-regular-13-letter-spacing);
    line-height: var(--footnote-regular-13-line-height);
    margin-top: -1px;
    position: relative;
    width: 268px;
  }
  
  .rotuine-detail-view .overlap-group-wrapper {
    height: 287px;
    left: 23px;
    position: absolute;
    top: 187px;
    width: 318px;
  }
  
  .rotuine-detail-view .overlap-group-2 {
    height: 287px;
    position: relative;
  }
  
  .rotuine-detail-view .group-6 {
    height: 144px;
    left: 0;
    position: absolute;
    top: 0;
    width: 318px;
  }
  
  .rotuine-detail-view .group-7 {
    height: 144px;
  }
  
  .rotuine-detail-view .group-8 {
    height: 144px;
    width: 318px;
  }
  
  .rotuine-detail-view .group-9 {
    background-image: url(https://c.animaapp.com/Ko3Ssjzi/img/rectangle-68-4.svg);
    background-size: 100% 100%;
    height: 144px;
    position: relative;
    width: 318px;
  }
  
  .rotuine-detail-view .image {
    height: 97px;
    left: 41px;
    object-fit: cover;
    position: absolute;
    top: 18px;
    width: 36px;
  }
  
  .rotuine-detail-view .frame-6 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    left: 102px;
    position: absolute;
    top: 14px;
  }
  
  .rotuine-detail-view .frame-7 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 6px;
    height: 52px;
    position: relative;
  }
  
  .rotuine-detail-view .frame-8 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .rotuine-detail-view .text-wrapper-11 {
    color: #000000;
    font-family: var(--footnote-semibold-13-font-family);
    font-size: var(--footnote-semibold-13-font-size);
    font-style: var(--footnote-semibold-13-font-style);
    font-weight: var(--footnote-semibold-13-font-weight);
    letter-spacing: var(--footnote-semibold-13-letter-spacing);
    line-height: var(--footnote-semibold-13-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .rotuine-detail-view .text-wrapper-12 {
    color: #000000;
    font-family: var(--footnote-regular-13-font-family);
    font-size: var(--footnote-regular-13-font-size);
    font-style: var(--footnote-regular-13-font-style);
    font-weight: var(--footnote-regular-13-font-weight);
    letter-spacing: var(--footnote-regular-13-letter-spacing);
    line-height: var(--footnote-regular-13-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .rotuine-detail-view .frame-9 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    margin-bottom: -2px;
    position: relative;
  }
  
  .rotuine-detail-view .text-wrapper-13 {
    color: var(--system-gray-03-dark);
    font-family: var(--caption-3-medium-10-font-family);
    font-size: var(--caption-3-medium-10-font-size);
    font-style: var(--caption-3-medium-10-font-style);
    font-weight: var(--caption-3-medium-10-font-weight);
    letter-spacing: var(--caption-3-medium-10-letter-spacing);
    line-height: var(--caption-3-medium-10-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .rotuine-detail-view .ellipse-3 {
    background-color: #d9d9d9;
    border-radius: 1px;
    height: 2px;
    position: relative;
    width: 2px;
  }
  
  .rotuine-detail-view .frame-10 {
    align-items: flex-start;
    display: inline-flex;
    gap: 12px;
    left: 102px;
    position: absolute;
    top: 82px;
  }
  
  .rotuine-detail-view .frame-11 {
    align-items: flex-start;
    display: flex;
    gap: 12px;
    position: relative;
    width: 212px;
  }
  
  .rotuine-detail-view .group-10 {
    height: 40.86px;
    position: relative;
    width: 44px;
  }
  
  .rotuine-detail-view .group-11 {
    height: 40px;
    position: relative;
    width: 144px;
  }
  
  .rotuine-detail-view .group-12 {
    height: 40px;
  }
  
  .rotuine-detail-view .frame-12 {
    align-items: center;
    background-color: var(--system-pink-light);
    border: 1px solid;
    border-color: var(--system-pink-light);
    border-radius: 12px;
    display: flex;
    gap: 12px;
    justify-content: center;
    overflow: hidden;
    padding: 13px 0px;
    position: relative;
    width: 144px;
  }
  
  .rotuine-detail-view .pencil {
    height: 12.77px;
    position: relative;
    width: 12.56px;
  }
  
  .rotuine-detail-view .text-wrapper-14 {
    color: var(--white);
    font-family: var(--caption-1-semibold-12-font-family);
    font-size: var(--caption-1-semibold-12-font-size);
    font-style: var(--caption-1-semibold-12-font-style);
    font-weight: var(--caption-1-semibold-12-font-weight);
    letter-spacing: var(--caption-1-semibold-12-letter-spacing);
    line-height: var(--caption-1-semibold-12-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .rotuine-detail-view .rectangle-2 {
    height: 144px;
    left: 0;
    position: absolute;
    top: 143px;
    width: 318px;
  }
  
  .rotuine-detail-view .frame-13 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 120px;
    left: 1px;
    padding: 24px;
    position: absolute;
    top: 149px;
    width: 316px;
  }
  
  .rotuine-detail-view .heyjenny-s-review {
    color: transparent;
    font-family: "SF Pro-Regular", Helvetica;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin-right: -11px;
    margin-top: -1px;
    position: relative;
    width: 279px;
  }
  
  .rotuine-detail-view .span {
    color: #ff2d55;
    font-family: var(--footnote-semibold-13-font-family);
    font-size: var(--footnote-semibold-13-font-size);
    font-style: var(--footnote-semibold-13-font-style);
    font-weight: var(--footnote-semibold-13-font-weight);
    letter-spacing: var(--footnote-semibold-13-letter-spacing);
    line-height: var(--footnote-semibold-13-line-height);
  }
  
  .rotuine-detail-view .text-wrapper-15 {
    color: #1c1c1e;
    font-family: var(--footnote-regular-13-font-family);
    font-size: var(--footnote-regular-13-font-size);
    font-style: var(--footnote-regular-13-font-style);
    font-weight: var(--footnote-regular-13-font-weight);
    letter-spacing: var(--footnote-regular-13-letter-spacing);
    line-height: var(--footnote-regular-13-line-height);
  }
  
  .rotuine-detail-view .frame-14 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    margin-bottom: -12px;
    margin-right: -30px;
    position: relative;
  }
  
  .rotuine-detail-view .vector {
    height: 15px;
    position: relative;
    width: 15px;
  }
  
  .rotuine-detail-view .text-wrapper-16 {
    color: var(--system-pink-light);
    font-family: var(--footnote-regular-13-font-family);
    font-size: var(--footnote-regular-13-font-size);
    font-style: var(--footnote-regular-13-font-style);
    font-weight: var(--footnote-regular-13-font-weight);
    height: 18px;
    letter-spacing: var(--footnote-regular-13-letter-spacing);
    line-height: var(--footnote-regular-13-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 273px;
  }
  
  .rotuine-detail-view .group-13 {
    height: 144px;
    left: 23px;
    position: absolute;
    top: 486px;
    width: 318px;
  }
  
  .rotuine-detail-view .group-14 {
    background-image: url(https://c.animaapp.com/Ko3Ssjzi/img/rectangle-68-2.svg);
    background-size: 100% 100%;
    height: 144px;
    position: relative;
  }
  
  .rotuine-detail-view .frame-15 {
    align-items: flex-start;
    border-radius: 12px;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    left: 102px;
    position: absolute;
    top: 14px;
  }
  
  .rotuine-detail-view .frame-16 {
    align-items: flex-start;
    border-radius: 12px;
    display: inline-flex;
    gap: 12px;
    left: 102px;
    position: absolute;
    top: 82px;
  }
  
  .rotuine-detail-view .group-15 {
    height: 474px;
    left: 0;
    position: absolute;
    top: 853px;
    width: 345px;
  }
  
  .rotuine-detail-view .frame-17 {
    align-items: center;
    background-color: var(--system-gray-06-light);
    border-radius: 0px 12px 12px 12px;
    display: inline-flex;
    gap: 12px;
    justify-content: center;
    left: 23px;
    padding: 24px;
    position: absolute;
    top: 37px;
  }
  
  .rotuine-detail-view .frame-18 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 118px;
    left: 1px;
    padding: 24px;
    position: absolute;
    top: 149px;
    width: 316px;
  }
  
  .rotuine-detail-view .frame-19 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    margin-bottom: -14px;
    margin-right: -30px;
    position: relative;
  }
  
  .rotuine-detail-view .group-16 {
    height: 175px;
    left: 0;
    position: absolute;
    top: 654px;
    width: 345px;
  }
  
  :root {
    --blue-1: rgba(127, 153, 185, 1);
    --blue-2: rgba(151, 181, 222, 1);
    --body-italic-17-font-family: "SFPro-RegularItalic", Helvetica;
    --body-italic-17-font-size: 17px;
    --body-italic-17-font-style: italic;
    --body-italic-17-font-weight: 400;
    --body-italic-17-letter-spacing: 0px;
    --body-italic-17-line-height: 22px;
    --body-regular-17-font-family: "Inter-Regular", Helvetica;
    --body-regular-17-font-size: 17px;
    --body-regular-17-font-style: normal;
    --body-regular-17-font-weight: 400;
    --body-regular-17-letter-spacing: 0px;
    --body-regular-17-line-height: 22px;
    --callout-regular-16-font-family: "SFPro-Regular", Helvetica;
    --callout-regular-16-font-size: 16px;
    --callout-regular-16-font-style: normal;
    --callout-regular-16-font-weight: 400;
    --callout-regular-16-letter-spacing: 0px;
    --callout-regular-16-line-height: 21px;
    --callout-regular-italic-16-font-family: "SFPro-RegularItalic", Helvetica;
    --callout-regular-italic-16-font-size: 16px;
    --callout-regular-italic-16-font-style: italic;
    --callout-regular-italic-16-font-weight: 400;
    --callout-regular-italic-16-letter-spacing: 0px;
    --callout-regular-italic-16-line-height: 21px;
    --callout-semibold-16-font-family: "SFPro-Semibold", Helvetica;
    --callout-semibold-16-font-size: 16px;
    --callout-semibold-16-font-style: normal;
    --callout-semibold-16-font-weight: 400;
    --callout-semibold-16-letter-spacing: 0px;
    --callout-semibold-16-line-height: 21px;
    --callout-semibold-italic-16-font-family: "SFPro-SemiboldItalic", Helvetica;
    --callout-semibold-italic-16-font-size: 16px;
    --callout-semibold-italic-16-font-style: italic;
    --callout-semibold-italic-16-font-weight: 400;
    --callout-semibold-italic-16-letter-spacing: 0px;
    --callout-semibold-italic-16-line-height: 21px;
    --caption-1-regular-12-font-family: "SFPro-Regular", Helvetica;
    --caption-1-regular-12-font-size: 12px;
    --caption-1-regular-12-font-style: normal;
    --caption-1-regular-12-font-weight: 400;
    --caption-1-regular-12-letter-spacing: 0px;
    --caption-1-regular-12-line-height: normal;
    --caption-1-regular-italic-12-font-family: "SFPro-Regular", Helvetica;
    --caption-1-regular-italic-12-font-size: 12px;
    --caption-1-regular-italic-12-font-style: normal;
    --caption-1-regular-italic-12-font-weight: 400;
    --caption-1-regular-italic-12-letter-spacing: 0px;
    --caption-1-regular-italic-12-line-height: normal;
    --caption-1-semibold-12-font-family: "SFPro-Semibold", Helvetica;
    --caption-1-semibold-12-font-size: 12px;
    --caption-1-semibold-12-font-style: normal;
    --caption-1-semibold-12-font-weight: 400;
    --caption-1-semibold-12-letter-spacing: 0px;
    --caption-1-semibold-12-line-height: normal;
    --caption-1-semibold-italic-12-font-family: "SFPro-SemiboldItalic", Helvetica;
    --caption-1-semibold-italic-12-font-size: 12px;
    --caption-1-semibold-italic-12-font-style: italic;
    --caption-1-semibold-italic-12-font-weight: 400;
    --caption-1-semibold-italic-12-letter-spacing: 0px;
    --caption-1-semibold-italic-12-line-height: normal;
    --caption-2-medium-11-font-family: "Inter-Medium", Helvetica;
    --caption-2-medium-11-font-size: 11px;
    --caption-2-medium-11-font-style: normal;
    --caption-2-medium-11-font-weight: 500;
    --caption-2-medium-11-letter-spacing: 0px;
    --caption-2-medium-11-line-height: normal;
    --caption-2-medium-italic-11-font-family: "SFPro-MediumItalic", Helvetica;
    --caption-2-medium-italic-11-font-size: 11px;
    --caption-2-medium-italic-11-font-style: italic;
    --caption-2-medium-italic-11-font-weight: 500;
    --caption-2-medium-italic-11-letter-spacing: 0px;
    --caption-2-medium-italic-11-line-height: normal;
    --caption-2-regular-11-font-family: "SFPro-Regular", Helvetica;
    --caption-2-regular-11-font-size: 11px;
    --caption-2-regular-11-font-style: normal;
    --caption-2-regular-11-font-weight: 400;
    --caption-2-regular-11-letter-spacing: 0px;
    --caption-2-regular-11-line-height: normal;
    --caption-2-regular-italic-11-font-family: "SFPro-RegularItalic", Helvetica;
    --caption-2-regular-italic-11-font-size: 11px;
    --caption-2-regular-italic-11-font-style: italic;
    --caption-2-regular-italic-11-font-weight: 400;
    --caption-2-regular-italic-11-letter-spacing: 0px;
    --caption-2-regular-italic-11-line-height: normal;
    --caption-3-medium-10-font-family: "SFPro-Medium", Helvetica;
    --caption-3-medium-10-font-size: 10px;
    --caption-3-medium-10-font-style: normal;
    --caption-3-medium-10-font-weight: 500;
    --caption-3-medium-10-letter-spacing: 0px;
    --caption-3-medium-10-line-height: normal;
    --dark-purple-1: rgba(42, 33, 64, 1);
    --dark-purple-2: rgba(42, 33, 64, 1);
    --dirty-pink-1: rgba(215, 148, 147, 1);
    --dirty-pink-2: rgba(249, 219, 220, 1);
    --footnote-regular-13-font-family: "SFPro-Regular", Helvetica;
    --footnote-regular-13-font-size: 13px;
    --footnote-regular-13-font-style: normal;
    --footnote-regular-13-font-weight: 400;
    --footnote-regular-13-letter-spacing: 0px;
    --footnote-regular-13-line-height: 18px;
    --footnote-regular-italic-13-font-family: "SFPro-Regular", Helvetica;
    --footnote-regular-italic-13-font-size: 13px;
    --footnote-regular-italic-13-font-style: normal;
    --footnote-regular-italic-13-font-weight: 400;
    --footnote-regular-italic-13-letter-spacing: 0px;
    --footnote-regular-italic-13-line-height: 18px;
    --footnote-semibold-13-font-family: "SFPro-Semibold", Helvetica;
    --footnote-semibold-13-font-size: 13px;
    --footnote-semibold-13-font-style: normal;
    --footnote-semibold-13-font-weight: 400;
    --footnote-semibold-13-letter-spacing: 0px;
    --footnote-semibold-13-line-height: 18px;
    --footnote-semibold-italic-13-font-family: "SFPro-SemiboldItalic", Helvetica;
    --footnote-semibold-italic-13-font-size: 13px;
    --footnote-semibold-italic-13-font-style: italic;
    --footnote-semibold-italic-13-font-weight: 400;
    --footnote-semibold-italic-13-letter-spacing: 0px;
    --footnote-semibold-italic-13-line-height: 18px;
    --gray: rgba(114, 114, 114, 1);
    --green-1: rgba(141, 193, 171, 1);
    --green-2: rgba(163, 224, 199, 1);
    --headline-semibold-17-font-family: "SFPro-Semibold", Helvetica;
    --headline-semibold-17-font-size: 17px;
    --headline-semibold-17-font-style: normal;
    --headline-semibold-17-font-weight: 400;
    --headline-semibold-17-letter-spacing: 0px;
    --headline-semibold-17-line-height: 22px;
    --headline-semibold-italic-17-font-family: "SFPro-SemiboldItalic", Helvetica;
    --headline-semibold-italic-17-font-size: 17px;
    --headline-semibold-italic-17-font-style: italic;
    --headline-semibold-italic-17-font-weight: 400;
    --headline-semibold-italic-17-letter-spacing: 0px;
    --headline-semibold-italic-17-line-height: 22px;
    --large-title-bold-34-font-family: "SFPro-Bold", Helvetica;
    --large-title-bold-34-font-size: 34px;
    --large-title-bold-34-font-style: normal;
    --large-title-bold-34-font-weight: 700;
    --large-title-bold-34-letter-spacing: 0px;
    --large-title-bold-34-line-height: 41px;
    --large-title-regular-34-font-family: "SFPro-Regular", Helvetica;
    --large-title-regular-34-font-size: 34px;
    --large-title-regular-34-font-style: normal;
    --large-title-regular-34-font-weight: 400;
    --large-title-regular-34-letter-spacing: 0px;
    --large-title-regular-34-line-height: 41px;
    --light-purple-1: rgba(154, 135, 207, 1);
    --purple-01: rgba(91, 78, 150, 1);
    --purple-2: rgba(133, 119, 197, 1);
    --routines-drop-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
    --simple-drop-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    --subhead-regular-15-font-family: "SFPro-Regular", Helvetica;
    --subhead-regular-15-font-size: 15px;
    --subhead-regular-15-font-style: normal;
    --subhead-regular-15-font-weight: 400;
    --subhead-regular-15-letter-spacing: 0px;
    --subhead-regular-15-line-height: 20px;
    --subhead-regular-italic-15-font-family: "SFPro-RegularItalic", Helvetica;
    --subhead-regular-italic-15-font-size: 15px;
    --subhead-regular-italic-15-font-style: italic;
    --subhead-regular-italic-15-font-weight: 400;
    --subhead-regular-italic-15-letter-spacing: 0px;
    --subhead-regular-italic-15-line-height: 20px;
    --subhead-semibold-15-font-family: "SFPro-Semibold", Helvetica;
    --subhead-semibold-15-font-size: 15px;
    --subhead-semibold-15-font-style: normal;
    --subhead-semibold-15-font-weight: 400;
    --subhead-semibold-15-letter-spacing: 0px;
    --subhead-semibold-15-line-height: 20px;
    --subhead-semibold-italic-15-font-family: "SFPro-SemiboldItalic", Helvetica;
    --subhead-semibold-italic-15-font-size: 15px;
    --subhead-semibold-italic-15-font-style: italic;
    --subhead-semibold-italic-15-font-weight: 400;
    --subhead-semibold-italic-15-letter-spacing: 0px;
    --subhead-semibold-italic-15-line-height: 20px;
    --system-blue-light: rgba(0, 122, 255, 1);
    --system-gray-02-dark: rgba(99, 99, 102, 1);
    --system-gray-02-light: rgba(174, 174, 178, 1);
    --system-gray-03-dark: rgba(72, 72, 74, 1);
    --system-gray-03-light: rgba(199, 199, 204, 1);
    --system-gray-04-dark: rgba(58, 58, 60, 1);
    --system-gray-04-light: rgba(209, 209, 214, 1);
    --system-gray-05-dark: rgba(28, 28, 30, 1);
    --system-gray-05-light: rgba(229, 229, 234, 1);
    --system-gray-06-dark: rgba(28, 28, 30, 1);
    --system-gray-06-light: rgba(242, 242, 247, 1);
    --system-gray-light: rgba(142, 142, 147, 1);
    --system-green-light: rgba(52, 199, 89, 1);
    --system-indigo-light: rgba(88, 86, 214, 1);
    --system-orange-light: rgba(255, 149, 0, 1);
    --system-pink-light: rgba(255, 45, 85, 1);
    --system-purple-light: rgba(175, 82, 222, 1);
    --system-red-light: rgba(255, 59, 48, 1);
    --system-teal-light: rgba(90, 200, 250, 1);
    --system-yellow-light: rgba(255, 204, 0, 1);
    --title-1-bold-28-font-family: "SFPro-Bold", Helvetica;
    --title-1-bold-28-font-size: 28px;
    --title-1-bold-28-font-style: normal;
    --title-1-bold-28-font-weight: 700;
    --title-1-bold-28-letter-spacing: 0px;
    --title-1-bold-28-line-height: 36px;
    --title-1-regular-28-font-family: "SFPro-Regular", Helvetica;
    --title-1-regular-28-font-size: 28px;
    --title-1-regular-28-font-style: normal;
    --title-1-regular-28-font-weight: 400;
    --title-1-regular-28-letter-spacing: 0px;
    --title-1-regular-28-line-height: 36px;
    --title-2-bold-22-font-family: "SFPro-Bold", Helvetica;
    --title-2-bold-22-font-size: 22px;
    --title-2-bold-22-font-style: normal;
    --title-2-bold-22-font-weight: 700;
    --title-2-bold-22-letter-spacing: 0px;
    --title-2-bold-22-line-height: 28px;
    --title-2-regular-22-font-family: "SFPro-Regular", Helvetica;
    --title-2-regular-22-font-size: 22px;
    --title-2-regular-22-font-style: normal;
    --title-2-regular-22-font-weight: 400;
    --title-2-regular-22-letter-spacing: 0px;
    --title-2-regular-22-line-height: 28px;
    --title-3-regular-20-font-family: "SFPro-Regular", Helvetica;
    --title-3-regular-20-font-size: 20px;
    --title-3-regular-20-font-style: normal;
    --title-3-regular-20-font-weight: 400;
    --title-3-regular-20-letter-spacing: 0px;
    --title-3-regular-20-line-height: 25px;
    --title-3-semibold-20-font-family: "SFPro-Semibold", Helvetica;
    --title-3-semibold-20-font-size: 20px;
    --title-3-semibold-20-font-style: normal;
    --title-3-semibold-20-font-weight: 400;
    --title-3-semibold-20-letter-spacing: 0px;
    --title-3-semibold-20-line-height: 25px;
    --uicolorprimary: rgba(103, 80, 163, 1);
    --white: rgba(255, 255, 255, 1);
    --yellow-1: rgba(237, 225, 162, 1);
    --yellow-2: rgba(238, 237, 214, 1);
  }
  