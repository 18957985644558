body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading-text {
  text-align: center;
  padding-bottom: 32px;
}

.error-box {
  padding-bottom: 32px;
}

.error-text {
  text-align: center;
}

.logo-header {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  align-items: center;
  display: inline-flex;
  gap: 170px;
}

.cardImage {
  object-fit: cover;
  height: 250px;
  width: 100%;
  background-color: #C7C7CC;
}

.product-image {
  width: 80px;
  align-self: center;
  object-fit: cover;
}

.list-product-image {
  width: 90px;
  align-self: center;
  object-fit: cover;
  align-items: center;

}

.title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 10px;
}

.summary {
  border: 1px solid;
  border-radius: 10px;
  padding: 16px;
  position: relative;
}

.product-card-container {
  position: relative; /* This enables absolute positioning for child elements */
}

.card-number {
  position: absolute; 
  top: -20px; /* Adjust as needed */
  left: -15px; /* Adjust as needed */
  background-color: #FF2D55; /* The color of your circle */
  color: white; /* The text color */
  height: 40px; /* The height of your circle */
  width: 40px; /* The width of your circle */
  border-radius: 50%; /* Makes the div circular */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; /* Adjust font-size as needed */
  z-index: 1; /* Ensures the number is above the card */
}


.summary-div {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: -30px;
  background: white;
  border-radius: 5%;
}

.list-summary-div {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  background: white;
  border-radius: 5%;
}
.list-summary-div .username-box {
  display: inline-flex;
  gap: 8px;
  padding-bottom: 12px;
}

.routine-user-not-bold {
  font-weight: normal!important;
  font-size: 12px;
  padding-top: 6px;

}


.summary-div .username-box {
  display: inline-flex;
  gap: 8px;
  padding-bottom: 12px;
}

.summary-category-text {
  font-size: 16px;
  color: #FF2D55;
  font-weight: bold;
  padding-bottom: 10px;
}

.routine-username {
  font-size: 12px;
  font-weight: bold;
  padding-top: 6px;
}

.days-ago {
  font-size: 12px;
  color: #AEAEB2;
  padding-top: 6px;
}

.routine-description {
  font-size: 13px;
}

.routine-step-count {
  font-size: 13px;
}
.tag {
  color: var(--System-Pink-Light, #FF2D55);
  font-family: SF Pro;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.filled-star {
  color: #FF2D55;
}
.empty-star {
  color: #C7C7CC;
}

.holy-grail{
  color: var(--System-Gray-02-Dark, #636366);
  font-size: 14px;
  font-style: normal;
  font-weight: 590;
  margin-left: 10px;
}
.routine-steps-title {
  font-size: 22px;
  font-weight: bold;
}

.star-rating{
  margin-top:10px;
  margin-bottom: 10px;
}
.step-parent {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
}

.step-frame {
  align-items: center;
  display: flex; /* changed from inline-flex */
  gap: 12px;
  position: relative; /* so that we can position the line pseudo-element relative to it */
}

.ellipse {
  background-color: var(--system-pink-light);
  border-radius: 6px;
  height: 12px;
  width: 12px;
  margin-top: -8px;
  position: relative;
  z-index: 1; /* ensures it's above the connecting line */
}

.vertical-line {
  position: absolute;
  top: 50%;
  left: 5px;
  width: 2px;
  background-color: #E5E5EA;
  z-index: 0;
}
.steps-container .step-frame:last-of-type::before {
  display: none;
}
.steps-container .last-step::before {
  display: none !important;
}

.steps-container .step-frame::before {
  background-color: red; /* just for debugging purposes */
}
.step-number {
  color: #FF2D55;
  font-size: 20px;
}

.routine-step {
  padding-left: 24px;
  padding-right: 12px;  
}

.routine-step-description {
  align-items: center;
  background-color: var(--system-gray-06-light);
  border-radius: 0px 12px 12px 12px;
  justify-content: center;
  padding: 24px;
  margin-left: 24px;
  font-size: 13px;
}

.product-card {
  border-radius: 12px 12px 12px 12px;
  border-color: var(--system-gray-06-light);
  border-width: 2px;
}

.product-view-group {
  align-items: center;
  display: flex;
  direction: row;
}

.product-view-information {
  align-items: left;
  display: flex;
  direction: column;
}

.shopping-button {
  border: 1px solid;
  margin-right: 10px;
  background-color: white;
  cursor: pointer;
  border-color: #FF2D55;
  color: #FF2D55;
  border-radius: 12px 12px 12px 12px;
}

.shopping-button:hover { 
  background-color: #FF2D55;
  color: white;
  border-color: #FFFFFF;
}

.review-button {
  color: white;
  background-color: #FF2D55;
  border: 1px solid;
  padding-left: 26px;
  padding-right: 36px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 12px 12px 12px 12px;
  font-size: 12px;
}

.review-button:hover {
  color: white;
  background-color: #FF2D55;
  border: 1px solid;
}

.product-review {
  align-items: center;
  background-color: var(--system-gray-06-light);
  justify-content: center;
  padding: 24px;
}

.product-review-username {
  font-size: 13px;
  font-weight: bold;
  color: #FF2D55;
}

.product-review-text {
  font-size: 13px;
}

.product-info-brand-name {
  font-size: 13px;
  font-weight: bold;
  /* align-items: center; */
  /* text-align: center; */
}

.list-product-info-brand-name {
  font-size: 13px;
  font-weight: bold;
}

.product-info-product-name {
  font-size: 13px;
}

.product-info-variant-name {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
}

.product-info-review-count {
  font-size: 10px;
  color: #48484A;
}

.info-footer-container {
  width: 394px;
  background: #f2f2f7;
}

.info-footer-download-group {
  padding-left: 16px;
  padding-top: 12px;
  align-items: center;
  display: inline-flex;
  gap: 60px;
}

.horizontal-line {
  color: #8E8E93;
}

.like-comment-count-group {
  display: flex;
  gap: 24px;
  justify-content: right;
  padding-right: 36px;
  padding-bottom: 12px;
}

.like-count-group {
  display: inline-flex;
  gap: 5px;
}

.comment-count-group {
  display: inline-flex;
  gap: 5px;
}

.like-comment-text {
  font-size: 12px;
  font-weight: bold;
}

.heart {
  margin-top: -6.25%;
}

.bubble-left {
  margin-top: -6.25%;
}

.copyright-info {
  padding-bottom: 16px;
  padding-top: 12px;
  font-size: 16px;
  color: #8E8E93;
  text-align: center;
}

.terms-group {
  padding-left: 111px;
  padding-bottom: 50px;
  align-items: center;
  display: inline-flex;
}

.terms-link {
  font-size: 16px;
  color: #8E8E93;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}


.horizontal-lines {
  position: relative; 
}

.horizontal-lines::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid #ccc; 
}

.horizontal-lines::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, transparent calc(25% - 1px), #ccc calc(25% - 1px), #ccc calc(75% - 1px), transparent calc(75% - 1px));
}

  .link-div {
    display: flex;
    align-items: flex-start; 
  }
  
  .vector {
    margin-top: 8px;
    margin-right: 10px; 
    align-self: flex-start; 
  }
  
  .text-wrapper {
    flex: 1; 
  }
  
  .link {
    color: var(--system-pink-light);
    font-family: var(--footnote-regular-13-font-family);
    font-size: var(--footnote-regular-13-font-size);
    font-style: var(--footnote-regular-13-font-style);
    font-weight: var(--footnote-regular-13-font-weight);
    letter-spacing: var(--footnote-regular-13-letter-spacing);
    line-height: var(--footnote-regular-13-line-height);
    white-space: normal; 
  }
  